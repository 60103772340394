.react-responsive-modal-overlay {
background: rgba(0, 0, 0, 0.75);
display: flex;
align-items: flex-start;
justify-content: center;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
overflow-y: auto;
overflow-x: hidden;
z-index: 1000;
padding: 1.2rem;
}
.react-responsive-modal-overlay-little {
align-items: center;
}
.react-responsive-modal-modal {
max-width: 800px;
position: relative;
padding: 1.2rem;
background: #ffffff;
background-clip: padding-box;
box-shadow: 0 12px 15px 0 rgba(0,0,0,0.25);
}
.react-responsive-modal-close-icon {
position: absolute;
top: 14px;
right: 14px;
cursor: pointer;
}
.react-responsive-modal-transition-enter {
opacity: 0.01;
}
.react-responsive-modal-transition-enter-active {
opacity: 1;
transition: opacity 500ms cubic-bezier(0.23, 1, 0.32, 1);
}
.react-responsive-modal-transition-exit {
opacity: 1;
}
.react-responsive-modal-transition-exit-active {
opacity: 0.01;
transition: opacity 500ms cubic-bezier(0.23, 1, 0.32, 1);
}
